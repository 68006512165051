import userRepository from "@/repository/user";
import { ActionContext } from "vuex";
import { responseErrorHandler } from "@/utils";
import { IInstructor, IUser } from "@/schemas/IUser";

export default {
  fetchUsers: (context: ActionContext<string, any>): void => {
    userRepository
      .getAll(context.rootState.token)
      .then((users) => context.commit("SET_USERS", users))
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchPoweredUsers: (context: ActionContext<string, any>): void => {
    userRepository
      .fetchPowered(context.rootState.token)
      .then((users) => context.commit("SET_USERS", users))
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchPoweredUsers2: (
    context: ActionContext<string, any>,
    next?: CallableFunction
  ): void => {
    userRepository
      .fetchPowered(context.rootState.token)
      .then((author: IUser[]) => {
        if (next) next(author);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchInstructors: (
    context: ActionContext<string, any>,
    next?: CallableFunction
  ): void => {
    userRepository
      .fetchInstructors(context.rootState.token)
      .then((instructors: IInstructor[]) => {
        if (next) next(instructors);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchWaitingValidation: (
    context: ActionContext<string, any>,
    next?: CallableFunction
  ): void => {
    userRepository
      .fetchWaitingValidation(context.rootState.token)
      .then((instructors: IInstructor[]) => {
        if (next) next(instructors);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchAdmins: (
    context: ActionContext<string, any>,
    next?: CallableFunction
  ): void => {
    userRepository
      .fetchAdmins(context.rootState.token)
      .then((admins: IUser[]) => {
        if (next) next(admins);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchUserSequencesSessions: (
    context: ActionContext<any, any>,
    payload: { sequenceId: string; next?: CallableFunction }
  ): void => {
    userRepository
      .getUserSequencesSessions(context.rootState.token, payload.sequenceId)
      .then((sessions) => {
        context.commit("SET_USER_SESSIONS", sessions);
        sessions.length
          ? context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Vos sessions ont bien été chargées.",
                snackColor: "info",
              },
              { root: true }
            )
          : context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Vous n'avez aucune session attribuée.",
                snackColor: "warning",
              },
              { root: true }
            );
      });
  },
  fetchUserSessions: (context: ActionContext<any, any>): void => {
    userRepository
      .getUserSessions(context.rootGetters.getToken)
      .then((sessions) => {
        context.commit("SET_USER_SESSIONS", sessions);
        sessions.length
          ? context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Vos sessions ont bien été chargées.",
                snackColor: "info",
              },
              { root: true }
            )
          : context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Vous n'avez aucune session attribuée.",
                snackColor: "warning",
              },
              { root: true }
            );
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  fetchUserSequences: (
    context: ActionContext<any, any>,
    next?: CallableFunction
  ): void => {
    userRepository
      .getUserSequences(context.rootGetters.getToken)
      .then((sequences) => {
        if (next) next(sequences);
        // sequences.length
        //   ? context.commit(
        //       "SET_SNACK",
        //       {
        //         displaySnack: true,
        //         snackText: "Vos sequences ont bien été chargées.",
        //         snackColor: "info",
        //       },
        //       { root: true }
        //     )
        //   : context.commit(
        //       "SET_SNACK",
        //       {
        //         displaySnack: true,
        //         snackText: "Vous n'avez aucune sequences attribuée.",
        //         snackColor: "warning",
        //       },
        //       { root: true }
        //     );
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  createUser: (
    context: ActionContext<any, any>,
    payload: {
      user: IUser;
      token: string;
      next: CallableFunction;
      errorNext?: CallableFunction;
    }
  ): void => {
    userRepository
      .create(payload.token, payload.user)
      .then((response): void => {
        const notification = {
          color: "green",
          message: `${response.username}, votre compte a été crée avec succès.`,
          id: "email sent",
          link: {
            label: "Se connecter",
            name: "login",
          },
        };
        context.commit("SET_NOTIFICATIONS", [notification], { root: true });
        if (payload.next) payload.next();
      })
      .catch((error) => {
        if (payload.errorNext) payload.errorNext(error);
        responseErrorHandler(error, context);
      });
  },

    deleteUserSequence:(
        context: ActionContext<any, any>,
        payload: {
            sequence_code: string,
            users: [],
            token: string,
            next: CallableFunction;
            errorNext?: CallableFunction;
        }
    ): void => {
        userRepository
            .deleteUserSequence( context.rootState.token, payload.sequence_code, payload.users)
            .then((response) => {
                console.log(response);
                if (payload.next) {
                    payload.next(response);
                }
            })
            .catch((error) => responseErrorHandler(error, context))
    },
  validateWaitingUser:(
    context: ActionContext<any, any>,
    payload: {
      users: [],
      token: string,
      next: CallableFunction;
      errorNext?: CallableFunction;
    }
  ): void => {
    userRepository
      .validateWaitingUser( context.rootState.token, payload.users)
      .then((response) => {
        console.log(response);
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => responseErrorHandler(error, context))
  },
  deleteWaitingUser:(
    context: ActionContext<any, any>,
    payload: {
      users: string,
      token: string,
      next: CallableFunction;
      errorNext?: CallableFunction;
    }
  ): void => {
    userRepository
      .deleteWaitingUser( context.rootState.token, payload.users)
      .then((response) => {
        console.log(response);
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => responseErrorHandler(error, context))
  },

    createNUsers: (
        context: ActionContext<any, any>,
        payload: {
            qte: number;
            sequence_code: string;
            token: string;
            next: CallableFunction;
            errorNext?: CallableFunction;
        }
    ): void => {
        console.log(payload.sequence_code)
        userRepository
            .createNUsers(context.rootState.token, payload.sequence_code, payload.qte)
            .then((response): void => {
                payload.next(response);
            })
            .catch((error) => responseErrorHandler(error, context))

    },


  fetchCurrentUser: (context: ActionContext<any, any>): void => {
    userRepository
      .getCurrentUser(context.rootState.token)
      .then((user: IUser) => {
        context.commit("SET_USERNAME", user.username);
        context.commit("SET_ROLE", user.role);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
};
