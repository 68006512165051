import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "@/store";
import { statusMessage } from "@/utils/httpUtils";

export const routes: Array<RouteConfig> = [
  {
    path: "/admin",
    name: "admin",
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      browserCompatibility: true,
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/AdminPage.vue"),
    children: [
      {
        path: "crud/:slug",
        name: "crud",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "crudDetail" */ "@/views/admin/CrudDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/session/:sessionId",
    name: "session",
    props: true,
    meta: { requiresAuth: true, browserCompatibility: true },
    // TODO add is_allowed that filter to check if it is an instructor/researcher/admin or a member of the group
    component: () =>
      import(
        /* webpackChunkName: "session" */ "@/views/session/SessionPage.vue"
      ),
    children: [
      {
        path: "overview",
        name: "overview",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "overview" */ "@/views/task/ranking/OverviewPage.vue"
          ),
      },
      {
        path: "detail",
        name: "taskDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "taskDetail" */ "@/views/task/ranking/TaskDetailsPage.vue"
          ),
      },
      {
        path: "single",
        name: "singleSession",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "singleSession" */ "@/views/task/ranking/SingleRankingTaskPage.vue"
          ),
      },
      {
        path: "transition",
        name: "transitionSession",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "transitionSession" */ "@/views/task/ranking/TransitionPage.vue"
          ),
      },
      {
        path: "tips-overview",
        name: "tipsOverview",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "tipsOverview" */ "@/views/task/ranking/TipsOverviewPage.vue"
          ),
      },
      {
        path: "group",
        name: "groupSession",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "groupSession" */ "@/views/session/GroupSession.vue"
          ),
      },
    ],
  },
  {
    path: "/join/:code",
    name: "sequenceJoin",
    props: true,
    meta: { browserCompatibility: true },
    async beforeEnter(
      to: Route,
      from: Route,
      next: NavigationGuardNext
    ): Promise<any> {
      await store.dispatch("sequence/fetchByCode", {
        code: to.params.code,
        onError: (error: any) => {
          if (error.message === statusMessage["404"])
            next({ name: "notFound" });
        },
      });
      next();
    },
    component: () =>
      import(
        /* webpackChunkName: "sequenceJoin" */ "@/views/sequence/SequenceJoiningPage.vue"
      ),
    // TODO : check if sequence exists
  },
  {
    path: "/sequence/:id",
    name: "sequence",
    props: true,
    meta: { requiresAuth: true, browserCompatibility: true },
    component: () =>
      import(
        /* webpackChunkName: "sequence" */ "@/views/sequence/SequencePage.vue"
      ),
    children: [
      {
        path: ":code/requests",
        name: "sequenceRequests",
        props: true,
        meta: {
          requiresAdmin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "requests" */ "@/views/sequence/RequestsPage.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/user",
  //   name: "profile",
  //   props: true,
  //   meta: { requiresAuth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "profile" */ "@/views/UserPage.vue"),
  // },
  {
    path: "/assignment/:sessionId",
    name: "assignment",
    props: true,
    meta: { browserCompatibility: true },
    component: () =>
      import(
        /* webpackChunkName: "assignmentIntro" */ "@/views/task/assignment/intro/IntroPage.vue"
      ),
    // children: [
    //   {
    //     path: "/assignment/:sessionId/single",
    //     name: "assignmentSingleSession",
    //     props: true,
    //     meta: {
    //       browserCompatibility: true,
    //     },
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "assignmentSingleSession" */ "@/views/task/assignment/AssignmentActivityPage.vue"
    //       ),
    //   },
    // ],
  },
  {
    path: "/assignment/:sessionId/single",
    name: "assignmentSingleSession",
    props: true,
    meta: {
      browserCompatibility: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "assignmentSingleSession" */ "@/views/task/assignment/AssignmentActivityPage.vue"
      ),
  },
  {
    path: "/feedback/:sessionId",
    name: "feedback",
    props: true,
    meta: { requiresAuth: true, browserCompatibility: true },
    // TODO add is_allowed that filter to check if it is an instructor/researcher/admin or a member of the group
    component: () =>
      import(/* webpackChunkName: "feedback" */ "@/views/FeedbackPage.vue"),
  },
  {
    path: "/login/:student?",
    name: "login",
    props: true,
    meta: { browserCompatibility: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginPage.vue"),
    // TODO : check if user is auth, if he is then redirect to home
  },
  {
    path: "/register",
    name: "registration",
    props: (route) => ({
      token: route.query.token || null,
    }),
    meta: { browserCompatibility: true },
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/RegistrationPage.vue"
      ),
  },
  {
    path: "/pwdrecover",
    name: "pwdrecover",
    props: true,
    meta: { browserCompatibility: true },
    component: () =>
      import(/* webpackChunkName: "pwdrecover */ "@/views/pwdrecover.vue"),
  },
  {
    path: "/pwdvalidate",
    name: "pwdvalidate",
    props: true,
    meta: { browserCompatibility: true },
    component: () =>
        import(/* webpackChunkName: "pwdvalidate */ "@/views/PwdValidate.vue"),
  },
  {
    path: "/renew_pwd/:uid",
    name: "renew_pwd",
    props: true,
    meta: { browserCompatibility: true },
    component: () =>
        import(/* webpackChunkName: "renew_pwd */ "@/views/RenewPwdPage.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: { browserCompatibility: true },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomePage.vue"),
  },

  {
    path: "/registerAdmin",
    name: "registerAdminPage",
    meta: { browserCompatibility: true },
    component: () =>
      import(/* webpackChunkName: "registerAdminPage" */ "@/views/admin/registerAdminPage.vue"),
  },

  {
    path: "/support/following",
    name: "following",
    meta: { browserCompatibility: true, requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "following" */ "@/views/support/Following.vue"),
  },

  {
    path: "/support/dashboard",
    name: "dashboard",
    meta: { browserCompatibility: true, requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/NotImplemented.vue"),
  },

  {
    path: "/support/module",
    name: "module",
    meta: { browserCompatibility: true, requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "module" */ "@/views/NotImplemented.vue"),
  },

  {
    path: "/about",
    name: "about",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutPage.vue"),
  },

  // {
  //   path: "/register",
  //   name: "register",
  //   meta: {},
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "@/views/RegisterUser.vue"),
  // },

  {
    path: "/not-supported",
    name: "notSupported",
    component: () =>
      import(/* webpackChunkName: "notSupported" */ "@/views/NotSupported.vue"),
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    meta: { browserCompatibility: true },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue"),
  },
];
