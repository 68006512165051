import repository from "@/repository";
import { parseJWT, responseErrorHandler } from "@/utils";
import { statusMessage } from "@/utils/httpUtils";
import { ActionContext, ActionTree } from "vuex";
import { IToken } from "@/schemas/IToken";
import { ICreds } from "@/schemas/ICreds";

function handleTokenResponse(
  context: ActionContext<string, any>,
  response: IToken
) {
  // Cache both renewal and current token
  context.commit("SET_TOKEN", response.access_token);
  context.commit("SET_RENEWAL_TOKEN", response.renewal_token);

  // Cache user identifier
  const tokenData = parseJWT(response.access_token);
  context.commit("user/SET_USER_ID", tokenData?.sub);

  context.commit(
    "SET_IS_AUTHENTICATED",
    !!window.localStorage.getItem("eccipe:token")
  );
  context.dispatch("user/fetchCurrentUser");
}

const actions: ActionTree<any, any> = {
  getToken: (
    context: ActionContext<string, any>,
    payload: {
      credentials: ICreds;
      next: CallableFunction;
      errorNext?: CallableFunction;
    }
  ) => {
    repository
      .getToken(payload.credentials)
      .then((response: IToken) => {
        handleTokenResponse(context, response);
        payload.next();
      })
      .catch((error) => {
        switch (error.message) {
          case statusMessage["400"]:
            console.log(statusMessage["400"])
            context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Identifiant ou mot de passe invalide.",
                snackColor: "warning",
                snackTimeOut: 4000,
                snackClosable: true,
              },
              { root: true }
            );
            break;
          case statusMessage["404"]:
            console.log(statusMessage["404"])
            context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Aucun utilisateur trouvé avec cet identifiant.",
                snackColor: "warning",
                snackTimeOut: 4000,
                snackClosable: true,
              },
              { root: true }
            );
            break;
          case statusMessage["422"]:
            console.log(statusMessage["422"])
            context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Veuillez remplir tous les champs du formulaire.",
                snackColor: "warning",
                snackTimeOut: 4000,
                snackClosable: true,
              },
              { root: true }
            );
            break;
          default:
            console.log(`${error.message} while getToken`);
            console.log(error)
        }
        if (payload.errorNext) payload.errorNext();
      });
  },
  getPwdRecover:(
   context: ActionContext<string, any>,
   payload: {
       email: string,
       adrWeb: string,
       next: CallableFunction,
       errorNext: CallableFunction;
   }
  ) => {
      repository
          .getPwdRecover(payload.email, payload.adrWeb)
          .then((response) => {
              console.log("getPwdRecover");
              if(payload.next) payload.next(response.message);
          })
          .catch((error) => {
                  if(payload.next) payload.errorNext(error.message);
          })
  },

  checkUuid:(
      context: ActionContext<string, any>,
      payload: {
          uid: string,
          next: CallableFunction,
          errorNext?: CallableFunction;
  }) => {
      // console.log(`store actions check:${payload.uid}`);
      repository
          .checkUid(payload.uid)
          .then((response) => {
              if(payload.next) {
                  payload.next(response);
              }
          })
          .catch((error) => {
              if (error.message != statusMessage["400"]) {
                   if(payload.errorNext) {
                      payload.errorNext(error.message);
                  }
              }
          })
  },

    RenewPwd:(
        context: ActionContext<string, any>,
        payload: {
            user_id: string,
            token: string,
            new_pwd: string,
            next: CallableFunction,
            errorNext?: CallableFunction;
        }) => {
        // console.log(`store actions check:${payload.uid}`);
        repository
            .RenewPwd(payload.user_id, payload.token, payload.new_pwd)
            .then((response) => {
                if(payload.next) {
                    payload.next(response);
                }
            })
            .catch((error) => {
                if (error.message === statusMessage["404"]) {
                    if(payload.errorNext) {
                        payload.errorNext(error.message);
                    }
                }
            })
    },

  getRegistrationEmail: (
    context: ActionContext<string, any>,
    payload: {
      email: string;
      prenom: string;
      nom: string;
      eafc: string;
      password: string;
      next: CallableFunction;
      errorNext?: CallableFunction;
    }
  ) => {
    repository
      .getRegistrationEmail(payload.email, payload.prenom, payload.nom, payload.eafc, payload.password)
      .then((response) => {
        // const notification = {
        //   color: "green",
        //   message: response.message,
        //   id: "email sent",
        // };
        // context.commit("SET_NOTIFICATIONS", [notification], { root: true });
        if (payload.next) payload.next(response.message);
      })
      .catch((error) => {
        if (error.message === statusMessage["400"]) {
          const snack = {
            snackColor: "warning",
            snackText: `Cet email est déjà utilisé.`,
            snackClosable: true,
            snackTimeOut: 30000,
          };
          context.commit("SET_SNACK", snack);
        }
        responseErrorHandler(error, context);
        if (payload.errorNext) payload.errorNext();
      });
  },
  getTokenFromQuickCreds: (
    context: ActionContext<string, any>,
    payload: {
      credentials: {
        email: string;
        auth_uuid: string;
      };
      sequenceCode: string;
      next: CallableFunction;
    }
  ) => {
    repository
      .getTokenFromQuickCreds(payload.credentials, payload.sequenceCode)
      .then((response: IToken) => {
        handleTokenResponse(context, response);
        payload.next();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  /**
   * Renew expired token
   * @param context
   * @param renewalToken one-time refresh token that allows another session and refresh token to be regenerated
   */
  renewToken: (context: ActionContext<string, any>, renewalToken: string) => {
    repository
      .getRenewedToken(renewalToken)
      .then((response: IToken) => {
        handleTokenResponse(context, response);
      })
      .catch();
  },
};

export default actions;
